import React from "react";
import "./UnderConstruction.css";

const UnderConstruction = () => {
   return (
      <div className="UnderConstruction">
         <h1>
            We Apologize for the Inconvenience, The site is currently under
            construction
         </h1>
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
            <g>
               <path
                  class="cls-1"
                  d="M231.91,282.27a125.09,125.09,0,0,0,26.69-22.42l28.06,16.21,25.56-44.27-28-16.17a123.15,123.15,0,0,0,6.05-34.36l31.86-5.62-8.88-50.33-31.85,5.62a124,124,0,0,0-17.48-30.18l20.83-24.81L245.59,43.09,224.78,67.88a123.1,123.1,0,0,0-32.77-12V23.58H140.9V55.92a123.22,123.22,0,0,0-32.77,12L87.33,43.09,48.17,75.94,69,100.75a123.67,123.67,0,0,0-17.47,30.18l-31.86-5.62-8.88,50.33,31.86,5.62a123.51,123.51,0,0,0,6.05,34.36l-28,16.17,25.56,44.27,28.06-16.21A124.51,124.51,0,0,0,101,282.27L89.92,312.71l48,17.47L149,299.79a123.33,123.33,0,0,0,34.89,0L195,330.19l48-17.49Zm-65.46,4.14A109.09,109.09,0,1,1,275.54,177.32,109.22,109.22,0,0,1,166.45,286.41Zm91.22-109.13a91.21,91.21,0,1,1-91.21-91.21A91.21,91.21,0,0,1,257.67,177.28Z"
               />
            </g>
            <g>
               <path
                  class="cls-1"
                  d="M493.56,194l.86-29.09L476,164.35a70.8,70.8,0,0,0-6.25-18.87l14.46-11.42-18-22.83-14.45,11.41a70.76,70.76,0,0,0-16.92-10.41l3.74-18.06-28.5-5.91-3.74,18.06a69.83,69.83,0,0,0-19.66,2.85L377.92,93l-25.63,13.8L361,123a69.79,69.79,0,0,0-13.2,14.85L330.69,131l-10.76,27,17.14,6.83a70.5,70.5,0,0,0-.61,19.85L319,190.5l9.13,27.64,17.49-5.78A70.34,70.34,0,0,0,357.91,228l-9.68,15.68L373,258.92l9.7-15.7a71.16,71.16,0,0,0,19.43,4l2.66,18.26,28.81-4.21L431,243.07A71.82,71.82,0,0,0,440,239a69.33,69.33,0,0,0,8.42-5.32L462.2,245.9l19.36-21.74L467.79,211.9a71.07,71.07,0,0,0,7.32-18.45ZM424.2,209.58a37.17,37.17,0,1,1,15.1-50.35A37.22,37.22,0,0,1,424.2,209.58Z"
               />
               <circle class="cls-1" cx="406.57" cy="176.86" r="23.56" />
            </g>
            <g>
               <path
                  class="cls-1"
                  d="M442.82,310.77,420.61,318a89.4,89.4,0,0,0-15.49-19.82l12.41-19.8-31.25-19.58-12.4,19.79a88.65,88.65,0,0,0-24.6-5.28l-3.22-23.11-36.52,5.09,3.22,23.11a88.6,88.6,0,0,0-22.22,11.81l-17.33-15.65L248.5,301.88l17.35,15.66a89.43,89.43,0,0,0-9.48,23.31L233.05,340l-1.33,36.85,23.32.84a88.79,88.79,0,0,0,7.75,23.95L244.38,416l22.67,29.08,18.44-14.37a90.19,90.19,0,0,0,21.3,13.37l-4.89,22.85,36.06,7.7,4.88-22.82a89.43,89.43,0,0,0,12.58-.85,90.34,90.34,0,0,0,12.34-2.62l10.94,20.61,32.57-17.27L400.33,431a90.27,90.27,0,0,0,16.83-18.68l21.67,8.78L452.68,387l-21.62-8.77A89.38,89.38,0,0,0,432,353l22.21-7.18Zm-93.23,98.29a47.09,47.09,0,1,1,40.14-53.14A47.14,47.14,0,0,1,349.59,409.06Z"
               />
               <path
                  class="cls-1"
                  d="M317.94,336a36.46,36.46,0,1,0,51.54,1.25A36.47,36.47,0,0,0,317.94,336Zm49.19,51.61a15.2,15.2,0,1,1,.51-21.48A15.2,15.2,0,0,1,367.13,387.63Zm-27.24-28.57a15.2,15.2,0,1,1,.51-21.48A15.21,15.21,0,0,1,339.89,359.06Z"
               />
            </g>
         </svg>
         <h4>
            Our website is currently undergoing maintenance. We are working
            diligently to bring it back online as soon as possible.
         </h4>
         <h4>
            In the meantime, if you need immediate assistance, please contact us
            at <a href="tel:+27792576600">079 257 6600</a> or email us at{" "}
            <a href="mailto:info@awethu.africa">info@awethu.africa</a>.
         </h4>
      </div>
   );
};

export default UnderConstruction;
