import "./App.css";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import Header from "./components/Header/Header";
import UnderConstruction from "./pages/UnderConstruction/UnderConstruction";

function App() {
   return (
      <div className="App">
         <BrowserRouter>
            <Routes>
               <Route element={<Outlet />}>
                  <Route element={<Header />}>
                     <Route path="/" element={<UnderConstruction />} />
                  </Route>
               </Route>
            </Routes>
         </BrowserRouter>
      </div>
   );
}

export default App;
