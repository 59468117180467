import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import "./Header.css";

const Header = () => {
   const [coverLinks, setCoverLinks] = useState(false);
   const [policyLinks, setPolicyLinks] = useState(false);

   const hover = (element) => {
      if (element === "cover") {
         setCoverLinks(!coverLinks);
      } else {
         setPolicyLinks(!policyLinks);
      }
   };

   const [openSideMenu, setOpenSideMenu] = useState(false);

   return (
      <div className="headerContainer">
         <div className="header">
            <div className="top">
               <div className="burgerMenu">
                  <div
                     className="sideMenu"
                     style={!openSideMenu ? { display: "none" } : null}
                  >
                     <div className="top">
                        <img src="/images/LogoWhite.png" alt="" />
                        <svg
                           viewBox="0 0 497.6 497.6"
                           className="close"
                           onClick={() => setOpenSideMenu(!openSideMenu)}
                        >
                           <g id="Layer_1-2" data-name="Layer 1">
                              <g id="Layer_1-2" data-name="Layer 1-2">
                                 <line
                                    className="cls-1"
                                    x1="25"
                                    y1="25"
                                    x2="467.6"
                                    y2="467.6"
                                 />
                                 <line
                                    className="cls-1"
                                    x1="467.6"
                                    y1="25"
                                    x2="25"
                                    y2="467.6"
                                 />
                              </g>
                           </g>
                        </svg>
                     </div>
                     <hr />
                     <nav>
                        <a href="/">
                           <h3>Home</h3>
                        </a>
                        <a href="/about">
                           <h3>About Us</h3>
                        </a>
                        <div
                           className="links"
                           onMouseLeave={() => hover("cover")}
                        >
                           <a href="/cover" onMouseEnter={() => hover("cover")}>
                              <h3>Cover</h3>
                           </a>
                           <ul style={!coverLinks ? { display: "none" } : null}>
                              <li>
                                 <a href="/cover/masincwabisane">
                                    <h3>Masincwabisane Group Cover</h3>
                                 </a>
                              </li>
                              <li>
                                 <a href="/cover/simunye">
                                    <h3>Simunye Family Cover</h3>
                                 </a>
                              </li>
                           </ul>
                        </div>
                        <div
                           className="links"
                           onMouseLeave={() => hover("policy")}
                        >
                           <a
                              href="/policy"
                              onMouseEnter={() => hover("policy")}
                           >
                              <h3>Policy</h3>
                           </a>
                           <ul
                              style={!policyLinks ? { display: "none" } : null}
                           >
                              <li>
                                 <a href="/policy/pre-burial">
                                    <h3>Pre-Burial</h3>
                                 </a>
                              </li>
                              <li>
                                 <a href="/policy/catering">
                                    <h3>Catering</h3>
                                 </a>
                              </li>
                              <li>
                                 <a href="/policy/tombstone">
                                    <h3>Tombstone</h3>
                                 </a>
                              </li>
                              <li>
                                 <a href="/policy/lifestock">
                                    <h3>Lifestock</h3>
                                 </a>
                              </li>
                              <li>
                                 <a href="/policy/grave">
                                    <h3>Grave</h3>
                                 </a>
                              </li>
                              <li>
                                 <a href="/policy/cremains">
                                    <h3>Cremains</h3>
                                 </a>
                              </li>
                           </ul>
                        </div>
                        <a href="/testimonials">
                           <h3>Testimonials</h3>
                        </a>
                        <a href="/gallery">
                           <h3>Gallery</h3>
                        </a>
                        <a href="/contact">
                           <h3>Contact</h3>
                        </a>
                        <a href="/faq">
                           <h3>FAQ</h3>
                        </a>
                     </nav>
                  </div>
                  <svg
                     id="Layer_2"
                     data-name="Layer 2"
                     xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 497.09 374.11"
                     // onClick={() => setOpenSideMenu(!openSideMenu)}
                  >
                     <g id="Layer_1-2" data-name="Layer 1">
                        <g id="Menu_Menu_Alt_03" data-name="Menu / Menu_Alt_03">
                           <path
                              class="cls-1"
                              d="m33.33,340.78h245.96M33.33,187.06h430.43M33.33,33.33h245.96"
                           />
                        </g>
                     </g>
                  </svg>
               </div>
               <div className="socials">
                  <a
                     href="https://instagram.com"
                     target="_blank"
                     rel="noopener noreferrer"
                  >
                     <svg
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 169.063 169.063"
                     >
                        <g>
                           <path
                              d="M122.406,0H46.654C20.929,0,0,20.93,0,46.655v75.752c0,25.726,20.929,46.655,46.654,46.655h75.752
		c25.727,0,46.656-20.93,46.656-46.655V46.655C169.063,20.93,148.133,0,122.406,0z M154.063,122.407
		c0,17.455-14.201,31.655-31.656,31.655H46.654C29.2,154.063,15,139.862,15,122.407V46.655C15,29.201,29.2,15,46.654,15h75.752
		c17.455,0,31.656,14.201,31.656,31.655V122.407z"
                           />
                           <path
                              d="M84.531,40.97c-24.021,0-43.563,19.542-43.563,43.563c0,24.02,19.542,43.561,43.563,43.561s43.563-19.541,43.563-43.561
		C128.094,60.512,108.552,40.97,84.531,40.97z M84.531,113.093c-15.749,0-28.563-12.812-28.563-28.561
		c0-15.75,12.813-28.563,28.563-28.563s28.563,12.813,28.563,28.563C113.094,100.281,100.28,113.093,84.531,113.093z"
                           />
                           <path
                              d="M129.921,28.251c-2.89,0-5.729,1.17-7.77,3.22c-2.051,2.04-3.23,4.88-3.23,7.78c0,2.891,1.18,5.73,3.23,7.78
		c2.04,2.04,4.88,3.22,7.77,3.22c2.9,0,5.73-1.18,7.78-3.22c2.05-2.05,3.22-4.89,3.22-7.78c0-2.9-1.17-5.74-3.22-7.78
		C135.661,29.421,132.821,28.251,129.921,28.251z"
                           />
                        </g>
                     </svg>
                  </a>
                  <a
                     href="https://facebook.com"
                     target="_blank"
                     rel="noopener noreferrer"
                  >
                     <svg
                        fill="#1877F2"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                     >
                        <path d="M12 2.03998C6.5 2.03998 2 6.52998 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.84998C10.44 7.33998 11.93 5.95998 14.22 5.95998C15.31 5.95998 16.45 6.14998 16.45 6.14998V8.61998H15.19C13.95 8.61998 13.56 9.38998 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96C15.9164 21.5878 18.0622 20.3855 19.6099 18.57C21.1576 16.7546 22.0054 14.4456 22 12.06C22 6.52998 17.5 2.03998 12 2.03998Z" />
                     </svg>
                  </a>
                  <a
                     href="https://x.com"
                     target="_blank"
                     rel="noopener noreferrer"
                  >
                     <svg
                        xmlns="http://www.w3.org/2000/svg"
                        enable-background="new 0 0 72 72"
                        viewBox="0 0 72 72"
                        id="twitter-x"
                        fill="var(--green)"
                     >
                        <switch>
                           <g>
                              <path
                                 d="M42.5,31.2L66,6h-6L39.8,27.6L24,6H4l24.6,33.6L4,66
			h6l21.3-22.8L48,66h20L42.5,31.2z M12.9,10h8l38.1,52h-8L12.9,10z"
                              ></path>
                           </g>
                        </switch>
                     </svg>
                  </a>
                  <a
                     href="http://linkedin.com"
                     target="_blank"
                     rel="noopener noreferrer"
                  >
                     <svg
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                     >
                        <path d="M6.5 8C7.32843 8 8 7.32843 8 6.5C8 5.67157 7.32843 5 6.5 5C5.67157 5 5 5.67157 5 6.5C5 7.32843 5.67157 8 6.5 8Z" />
                        <path d="M5 10C5 9.44772 5.44772 9 6 9H7C7.55228 9 8 9.44771 8 10V18C8 18.5523 7.55228 19 7 19H6C5.44772 19 5 18.5523 5 18V10Z" />
                        <path d="M11 19H12C12.5523 19 13 18.5523 13 18V13.5C13 12 16 11 16 13V18.0004C16 18.5527 16.4477 19 17 19H18C18.5523 19 19 18.5523 19 18V12C19 10 17.5 9 15.5 9C13.5 9 13 10.5 13 10.5V10C13 9.44771 12.5523 9 12 9H11C10.4477 9 10 9.44772 10 10V18C10 18.5523 10.4477 19 11 19Z" />
                        <path
                           fillRule="evenodd"
                           clipRule="evenodd"
                           d="M20 1C21.6569 1 23 2.34315 23 4V20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20V4C1 2.34315 2.34315 1 4 1H20ZM20 3C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3H20Z"
                        />
                     </svg>
                  </a>
               </div>
               <div className="logo">
                  <img src="/images/Logo.png" alt="" className="logo" />
               </div>
               <a href="tel:+27792576600" className="cta">
                  <p>
                     <b>Call now</b>
                  </p>
                  <p>079 257 6600</p>
                  <svg
                     viewBox="0 0 24 24"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                  >
                     <path
                        d="M3 5.5C3 14.0604 9.93959 21 18.5 21C18.8862 21 19.2691 20.9859 19.6483 20.9581C20.0834 20.9262 20.3009 20.9103 20.499 20.7963C20.663 20.7019 20.8185 20.5345 20.9007 20.364C21 20.1582 21 19.9181 21 19.438V16.6207C21 16.2169 21 16.015 20.9335 15.842C20.8749 15.6891 20.7795 15.553 20.6559 15.4456C20.516 15.324 20.3262 15.255 19.9468 15.117L16.74 13.9509C16.2985 13.7904 16.0777 13.7101 15.8683 13.7237C15.6836 13.7357 15.5059 13.7988 15.3549 13.9058C15.1837 14.0271 15.0629 14.2285 14.8212 14.6314L14 16C11.3501 14.7999 9.2019 12.6489 8 10L9.36863 9.17882C9.77145 8.93713 9.97286 8.81628 10.0942 8.64506C10.2012 8.49408 10.2643 8.31637 10.2763 8.1317C10.2899 7.92227 10.2096 7.70153 10.0491 7.26005L8.88299 4.05321C8.745 3.67376 8.67601 3.48403 8.55442 3.3441C8.44701 3.22049 8.31089 3.12515 8.15802 3.06645C7.98496 3 7.78308 3 7.37932 3H4.56201C4.08188 3 3.84181 3 3.63598 3.09925C3.4655 3.18146 3.29814 3.33701 3.2037 3.50103C3.08968 3.69907 3.07375 3.91662 3.04189 4.35173C3.01413 4.73086 3 5.11378 3 5.5Z"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                     />
                  </svg>
               </a>
            </div>
            {/* <nav className="menu">
               <a href="/">
                  <h4>Home</h4>
               </a>
               <a href="/about">
                  <h4>About Us</h4>
               </a>
               <div className="links" onMouseLeave={() => hover("cover")}>
                  <a href="/cover" onMouseEnter={() => hover("cover")}>
                     <h4>Cover</h4>
                  </a>
                  <ul style={!coverLinks ? { display: "none" } : null}>
                     <li>
                        <a href="/cover/masincwabisane">
                           <h4>Masincwabisane Group Cover</h4>
                        </a>
                     </li>
                     <li>
                        <a href="/cover/simunye">
                           <h4>Simunye Family Cover</h4>
                        </a>
                     </li>
                  </ul>
               </div>
               <div className="links" onMouseLeave={() => hover("policy")}>
                  <a href="/policy" onMouseEnter={() => hover("policy")}>
                     <h4>Policy</h4>
                  </a>
                  <ul style={!policyLinks ? { display: "none" } : null}>
                     <li>
                        <a href="/policy/pre-burial">
                           <h4>Pre-Burial</h4>
                        </a>
                     </li>
                     <li>
                        <a href="/policy/catering">
                           <h4>Catering</h4>
                        </a>
                     </li>
                     <li>
                        <a href="/policy/tombstone">
                           <h4>Tombstone</h4>
                        </a>
                     </li>
                     <li>
                        <a href="/policy/lifestock">
                           <h4>Lifestock</h4>
                        </a>
                     </li>
                     <li>
                        <a href="/policy/grave">
                           <h4>Grave</h4>
                        </a>
                     </li>
                     <li>
                        <a href="/policy/cremains">
                           <h4>Cremains</h4>
                        </a>
                     </li>
                  </ul>
               </div>
               <a href="/testimonials">
                  <h4>Testimonials</h4>
               </a>
               <a href="/gallery">
                  <h4>Gallery</h4>
               </a>
               <a href="/contact">
                  <h4>Contact</h4>
               </a>
               <a href="/faq">
                  <h4>FAQ</h4>
               </a>
            </nav> */}
         </div>
         <main>
            <Outlet />
         </main>
      </div>
   );
};

export default Header;
